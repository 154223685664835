import React from 'react';
import configError from '../../resources/images/configuration_error_icon.svg';
import { Message } from '../Message';
import classes from './ConfigurationInvalidError.module.scss';

export const ConfigurationInvalidError: React.FC<{
  isProbableApiMismatch: boolean;
}> = ({ isProbableApiMismatch }) => (
  <>
    {isProbableApiMismatch ? (
      <>
        <Message
          type="error"
          title={`Loading configuration from Mosaic Identity Service failed.`}
        />
        <img src={configError} className={classes.centerImage} />
        <p className={classes.center}>
          There was a problem loading the configuration from Mosaic Identity
          Service. This can happen when the version of the @axinom/mosaic-portal
          package is not the latest. Please upgrade the package to the latest
          version and try again.
        </p>
        <p className={classes.center}>
          If the problem persists, please contact
          <a href="mailto: support@axinom.com"> Axinom Support</a>.
        </p>
      </>
    ) : (
      <>
        <Message type="error" title={'Configuration not valid'} />
        <img src={configError} className={classes.centerImage} />
        <p className={classes.center}>
          Please check if at least one Identity Provider is configured for the
          environment and try again.
        </p>
        <p className={classes.center}>
          If the problem persists, please contact
          <a href="mailto: support@axinom.com"> Axinom Support</a>.
        </p>
      </>
    )}
  </>
);
